* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #000;
}

/* .app {
  padding: 50px 0;
} */

.videos {
  position: relative;
  outline: none;
}

.main-video, .midroll-video-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  outline: none;

}

.skip-ad-btn {
  position: absolute;
  border: 2px solid silver;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  color: #fff;
  right: 10px;
  bottom: 3em;
  display: flex;
  align-items: center;
}

.skip-ad-btn:hover, .play-icon:hover {
  opacity: .8;
  cursor: pointer;
}


.midroll-video {
  position: relative;
  margin: auto;
  outline: none;
}

#main-video_html5_api{
  outline: none;
}

.play-icon {
  position: absolute;
  color: #fff;
  font-size: 20em;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: 0;
}